import React, { useRef } from 'react'
import { useLocation } from '@reach/router';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'

import Header from '../components/header';
import "../sass/style.scss"
import 'swiper/css';
import 'swiper/css/effect-fade';


const Layout = ({ path, children }) => {
  const state = useTransitionState()
	const location = useLocation().pathname;
	const containerRef = useRef(null)
	const isHome = location === '/' ? 1 : 0;

	return (
		<LocomotiveScrollProvider
		  options={
		    {
		      smooth: true,
		      getDirection: true,
		      resetNativeScroll: false
		      // ... all available Locomotive Scroll instance options 
		    }
		  }
		  watch={
		    [ state
		      //..all the dependencies you want to watch to update the scroll.
		      //  Basicaly, you would want to watch page/location changes
		      //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
		    ]
		  }
		  containerRef={containerRef}
		>
			<div className={isHome ? 'home' : ''} data-scroll-container ref={containerRef}>
        {/*<div className="h1 popup"><div className="popup-inner">SOON</div></div>*/}
		    <Header/>
		    <main>
		      {children}
		    </main>
			</div>
		</LocomotiveScrollProvider>
	)
}

export default Layout;
