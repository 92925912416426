import { useStaticQuery, graphql } from "gatsby"

export const useACFOptionsQuery = () => {
  const data = useStaticQuery(graphql`
    query optionsQuery {  
      allWpPost {
        totalCount
      },
      wp {
        footer {
          themeOptions {
            footerText
            email
            instagram
            address
            stgu
          }
        }
      }
    }
  `)

  return data;
}
