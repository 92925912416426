import gsap from 'gsap';

export const fade = (el) => {
  gsap.fromTo(el, 
	{
		opacity: 0, 
		y: 100
	},
  {
    autoAlpha: 1,
    duration: 1.3,
    y: 0
  });
}


export const fadeIn = (el) => {
  gsap.fromTo(el, 
  {
    opacity: 0, 
    y: -10
  },
  {
    opacity: 1,
    duration: 1.3,
    y: 0,
    delay: .5
  });
}

export const generateRandomInteger = (min, max) => {
  return 0;//Math.floor(min + Math.random()*(max - min + 1))
}

export const getRatio = (width = 1, height = 1) => {
  return (height/width)* 100 + '%'
}