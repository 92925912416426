import React, { useEffect } from 'react';
import gsap from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';
import Hamburger from '../hamburger'
import { fadeIn } from '../../helpers/helpers';

export let exitTransition = {
  length: .8, // Take .5 seconds to leave
  trigger: () => {
    const inview = document.querySelectorAll('.is-inview');
    const hamburger = document.querySelector('.hamburger');
    if (hamburger !== null) {
      hamburger.classList.remove('is-active');
    }

    if (inview !== null) {
      inview.forEach(el => {
        el.classList.remove('is-inview');
      })
    }
    gsap.to('main', {
      autoAlpha: 0,
      //duration: 1,
      y: 10,
      delay: .8
    });
  }
}

export let entryTransition = {
  delay: 1, 
  length: 1,
  trigger: () => {
  gsap.fromTo('main', 
    { opacity: 0, y: 16 },
    {
      autoAlpha: 1,
      duration: 1,
      y: 0
    });
  }
}

const Header = () => {
  useEffect(() => {
    fadeIn('header');
  }, []);

  return (
    <header>
      <div className="container-fluid hover-underline">
        <div className="row">
          <div className="col-6 col-md-3">
          <TransitionLink
              to="/"
              exit={exitTransition}
              entry={entryTransition}
              className="fs-17 fw-medium logo"
            >
              MADE
            </TransitionLink>
          </div>
          <div className="d-none d-md-block col-md-3">
            <TransitionLink
                to="/"
                exit={exitTransition}
                entry={entryTransition}
                activeClassName="is-active"
                className="fs-17 fw-medium"
              >
                about
            </TransitionLink>
          </div>
          <div className="d-none d-md-block col-md-2">
          <TransitionLink
              to="/portfolio"
              activeClassName="is-active"
              exit={exitTransition}
              entry={entryTransition}
              className="fs-17 fw-medium"
            >
              portfolio feed
            </TransitionLink>
          </div>
          <div className="d-none d-md-block col-md-2">
            <TransitionLink
                to="/made-xcode"
                activeClassName="is-active"
                exit={exitTransition}
                entry={entryTransition}
                className="fs-17 fw-medium"
              >
                made.xcode
              </TransitionLink>
          </div>
          <div className="d-none d-md-block col-md-1">
            <TransitionLink
                to="/contact"
                activeClassName="is-active"
                exit={exitTransition}
                entry={entryTransition}
                className="fs-17 fw-medium"
              >
                contact
              </TransitionLink>
          </div>
          <div className="col-6 col-md-1 text-end">
            <Hamburger />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
