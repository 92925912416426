import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap"
import TransitionLink from 'gatsby-plugin-transition-link';
import logo from '../../images/static/logo-800.gif';
import entryTransition from '../header';
import exitTransition from '../header';
import { generateRandomInteger } from '../../helpers/helpers'

import { useACFOptionsQuery } from "../../components/hooks/useACFOptionsQuery"


//const overlay = gsap.timeline({ paused: true, reversed: true})
  const fadeMenu = gsap.timeline({ paused: true, reversed: true})

const Hamburger = () => {
  const [menuOpen, setMenuState] = useState(false);
  const menuRef = useRef()
  const navRef = useRef()
  exitTransition.delay = .9;

  const {
    wp: {
      footer: { themeOptions: data }
    },
  } = useACFOptionsQuery()
  const address = data.address + '<br/><a href="mailto:hello@madebymade.pl" class="d-inline-block">hello@madebymade.pl</a>';

  useEffect(() => {
    const menuLine1 = menuRef.current.querySelector("span")
    const menuLine2 = menuRef.current.querySelectorAll("span")[1]
    const menuLine3 = menuRef.current.querySelectorAll("span")[2]

    fadeMenu
      .to(menuLine1, .6, { left: 0, top: 7 }, "<")
      .to(menuLine2, .4, { opacity: 0, scaleX: 0 }, "<")
      .to(menuLine3, .4, { top: 7, delay: .1 }, "<")


    fadeMenu.eventCallback("onStart", function() {
      document.body.classList.add('is-loading');
    });

    fadeMenu.eventCallback("onReverseComplete", function() {
      hideContent();
    });
  })

  const hideContent = () => {
    const links = document.querySelectorAll('.nav .anim');
     document.body.classList.add('is-loading');
     links.forEach((link, i) => {
      let delay = generateRandomInteger(1, 4)
      gsap.fromTo(link, .5, { y: 0 }, { y: -10, opacity: 0,  ease: 'expo.easeIn', delay: delay/10 })

      if (i === links.length - 1) {
        hideOverlay();
      }
    })
  }

  const showContent = () => {
    const links = navRef.current.querySelectorAll('.anim');
     links.forEach((link, i) => {
      gsap.fromTo(link, 1, { y: 20 }, { y: 0, opacity: 1,  ease: 'expo.inOut', delay: i/10, onComplete: function() {
        if (i === links.length - 1) {
          document.body.classList.remove('is-loading');
        }
      }})
    })
  }

  const showOverlay = () => {
    const overlayInner = navRef.current.querySelectorAll('.overlay-inner');
    
    gsap.set(navRef.current, { opacity: 1, display: 'block' });
    overlayInner.forEach((col, i) => {
      gsap.fromTo(col, .8, { y: '100%' }, { y: 0, ease: 'expo.inOut', delay: i/10, onComplete: function() {
        if (i === 0) {
          document.body.classList.add('nav-open');
        }
        if (i === 2) {
          showContent();
        }
      }});
    })
  }

  const hideOverlay = () => {    
    const overlayInner = navRef.current.querySelectorAll('.overlay-inner');
    document.body.classList.remove('nav-open');

    overlayInner.forEach((col, i) => {
      gsap.fromTo(col, .6, { y: 0 }, { y: '-100%', ease: 'expo.inOut', delay: i/10, onComplete: function() {
        col.attributeStyleMap.clear()

        if (i === 2) {
          gsap.set(navRef.current, { opacity: 0, display: 'none' });
        }
      }});
    });
    document.body.classList.remove('is-loading');
  }

  const handleMenuClick = () => {
    setMenuState(!menuOpen);
    menuRef.current.parentElement.classList.toggle('is-active');
    fadeMenu.reversed() ? fadeMenu.play() : fadeMenu.reverse()
    if (!fadeMenu.reversed()) {
      showOverlay();
    }
  }

  const handleNavLinkClick = () => {
    //e.preventDefault();
    setMenuState(false);
    fadeMenu.reverse()
  }

  return (
    <>
      <button className="hamburger" aria-expanded={menuOpen} aria-label="Otwórz menu" onClick={handleMenuClick}>
        <span className="hamburger-wrapper" ref={menuRef}>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>

      <div className="nav" ref={navRef}>
        <div className="overlay">
          <div className="overlay-inner"></div>
          <div className="overlay-inner"></div>
          <div className="overlay-inner"></div>
        </div>
        <div className="container-fluid py-0 h-100">
          <div className="row h-100">
            <div className="col-md-4 d-flex order-2 order-md-0">
              <a target="_blank" rel="noreferrer" className="d-inline-block d-md-none anim" href={data.instagram}>instagram</a>
              
              {data.address && (
                <div className="mt-md-auto anim text-left address hover-rm-underline mb" style={{ '--delay': .2 }} dangerouslySetInnerHTML={{__html: address}}></div>
              )}

              <div className="easy text-left anim fw-medium d-inline-block d-md-none" style={{ '--delay': .2 }}>easy<br/>is<br/>boring</div>
            </div>
            <div className="col-md-4 d-flex flex-column">
              <nav>
                <ul>
                  <li className="anim">
                    <TransitionLink
                      onClick={handleNavLinkClick}
                      to="/"
                      exit={exitTransition}
                      entry={entryTransition}
                    >
                      About
                    </TransitionLink>
                  </li>
                  <li className="anim">
                    <TransitionLink
                      onClick={handleNavLinkClick}
                      activeClassName="is-active"
                      to="/portfolio"
                      exit={exitTransition}
                      entry={entryTransition}
                    >
                      Portfolio feed
                    </TransitionLink>
                  </li>
                  <li className="anim">
                    <TransitionLink
                      onClick={handleNavLinkClick}
                      to="/made-xcode"
                      activeClassName="is-active"
                      exit={exitTransition}
                      entry={entryTransition}
                    >
                      MADE.xcode
                    </TransitionLink>
                  </li>
                  <li className="anim">
                    <TransitionLink
                      onClick={handleNavLinkClick}
                      to="/contact"
                      activeClassName="is-active"
                      exit={exitTransition}
                      entry={entryTransition}
                    >
                      Contact
                    </TransitionLink>
                  </li>
                </ul>
              </nav>
              <div className="mt-auto fs-14 text-left d-none d-md-inline-block anim hover-rm-underline insta mb" style={{ '--delay': .1 }}>
                <a target="_blank" rel="noreferrer" href={data.instagram}>instagram</a>
              </div> 
            </div>
            <div className="col-md-4 d-flex d-none d-md-block">
              <div className="anim m-auto" style={{ '--delay': .2 }}>
                <img src={logo} alt="MADE by MADE" width={800} height={800} className="mx-auto" />
              </div>
              <div className="easy text-left anim fw-medium" style={{ '--delay': .2 }}>easy<br/>is<br/>boring</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hamburger
