module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://madenewdev1923179.madebymade.pl/graphql","includedRoutes":["/*/*/posts","/*/*/pages","/*/*/media"],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/vercel/path0/src/layouts/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MADE","short_name":"MADE","start_url":"/","background_color":"#ffffff","theme_color":"#000000","description":"MADE - design. brand. technology.","display":"standalone","icon":"src/images/favicons/fav.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"af0b42bf7d3a93023b59dfddff7b5bed"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
